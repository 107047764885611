import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AdminRoutes } from '../../../routes';
import { ChecklistContainer, ListingContainer } from '../containers';
import { AttributeContainer } from '../containers/atttibutes';

export const ChecklistRouter: FC = () => {
  return (
    <Switch>
      <Route
        path={[AdminRoutes.Checklists, AdminRoutes.ChecklistAttributes]}
        exact
        component={ListingContainer}
      />
      <Route
        path={[AdminRoutes.ChecklistBasicAttributeCreate, AdminRoutes.ChecklistBasicAttribute]}
        exact
        component={AttributeContainer}
      />
      <Route
        path={[AdminRoutes.ChecklistCreate, AdminRoutes.SelectedChecklist]}
        exact
        component={ChecklistContainer}
      />
    </Switch>
  );
};
