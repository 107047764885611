enum EImportOperationType {
  NOTES_IMPORT = 'NOTES_IMPORT',
  FILES_IMPORT = 'FILES_IMPORT',
  FULL_IMPORT = 'FULL_IMPORT',
}

enum EImportOperationStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

interface IImportOperationModel {
  id: string;
  type: EImportOperationType;
  isAuto: boolean;
  clientId: number;
  name: string;
  host: string;
  /**
   * формат `YYYY-MM-DD`
   */
  fromDate: string;
  /**
   * формат `YYYY-MM-DD`
   */
  toDate: string;
  reportsLocation: string;
  filesLocation: string;
  creationDate: string;
  operationStatus: EImportOperationStatus;
  message: string;
}

interface IPogodaVPoleImportOperationModel {
  id: string;
  dateFrom: string;
  dateTo: string;
  creationDate: string;
  status: EImportOperationStatus;
  message: string;
}

export type { IImportOperationModel, IPogodaVPoleImportOperationModel };

export { EImportOperationType, EImportOperationStatus };
