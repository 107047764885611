import { IGetChecklistAttribute } from './checklist.model';

export enum EChecklistAttributeType {
  Boolean = 'BOOLEAN',
  Int = 'INT',
  Double = 'DOUBLE',
  Date = 'DATE',
  String = 'STRING',
  Longstring = 'LONGSTRING',
  DictionaryLink = 'DICTIONARY_LINK',
  Enum = 'ENUM',
  UserDictionaryLink = 'USER_DICTIONARY_LINK',
  File_link = 'FILE_LINK',
  ChecklistInstanceLink = 'CHECKLIST_INSTANCE_LINK',
}

export enum EChecklistAttributeCalculationType {
  AVERAGE = 'AVERAGE',
}

export interface IChecklistAttributeEnumItem {
  id?: string;
  value: string;
  isActive: boolean;
  canDelete?: boolean;
}

export interface IChecklistAttributeEnumItemExtended extends IChecklistAttributeEnumItem {
  isHide?: boolean;
  isNew?: boolean;
  organizationId?: string;
}

export interface IChecklistAttribute {
  name: string;
  isRequired: boolean;
  isActive: boolean;
  checklistLink: string;
  checklistLinkPublicId: string;
  dictionaryLink: string;
  isMultiselect: boolean;
  displayParent: boolean;
  toolTip: string;
  placeholder: string;
  description: string;
  calculationType: 'AVERAGE';
  id: string;
  type: EChecklistAttributeType;
  isTitle: boolean;
}

export interface IAttributeInChecklist {
  order: number;
  isRequired?: boolean;
  isActive?: boolean;
  isTitle?: boolean;
  displayParent?: boolean;
  fileRequired?: boolean;
  toolTip?: string;
  parentId?: string;
  dependencyType?: EAttributeInChecklistDependencyType;
  dependencyAttributeId?: string;
  min?: number;
  max?: number;
  normal?: number;
  stageId?: string;
  checkListId?: string;
  attributeId?: string;
}

export enum EAttributeInChecklistDependencyType {
  CHECKLIST_ATTRIBUTE = 'CHECKLIST_ATTRIBUTE',
  CULTURE_TASK = 'CULTURE_TASK',
  PHENOPHASE_TASK = 'PHENOPHASE_TASK',
  OPERATION_TYPE_TASK = 'OPERATION_TYPE_TASK',
}

export interface IFileLinkInstance {
  isActive?: boolean;
  attribute?: IGetChecklistAttribute;
}

export interface IFileLinkInstanceClientOnly extends IFileLinkInstance {
  imageAttributeName: string;
  imageAttributeId: string;
  clientOnlyId?: string;
  isRequired?: boolean;
  isDirty?: boolean;
}

export interface IUserDictionaryItemValue {
  id: string;
  value: string;
  organizationId?: string;
  isActive: boolean;
}
