import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { FC, memo } from 'react';

const StringInput: FC<{
  itemKey: string;
  attributeFormItemsScheme: any;
  isTextarea?: boolean;
  disabled?: boolean;
  nullifyEmptyString?: boolean;
  maxLength?: number;
  onChangeHandler?: (value: any) => void;
}> = ({
  itemKey,
  isTextarea,
  disabled,
  attributeFormItemsScheme,
  onChangeHandler,
  nullifyEmptyString,
  maxLength,
}) => {
  const { values, setFieldValue, errors } = useFormikContext();

  return (
    <Field
      component={TextField}
      name={itemKey}
      type="text"
      value={values[itemKey] || ''}
      label={attributeFormItemsScheme[itemKey]?.label}
      disabled={attributeFormItemsScheme[itemKey]?.disabled || disabled}
      error={Boolean(errors[itemKey])}
      onChange={e => {
        let inputValue = e.target.value;

        if (nullifyEmptyString && inputValue === '') {
          inputValue = null;
        }
        setFieldValue(itemKey, inputValue);
        onChangeHandler?.(inputValue);
      }}
      fullWidth
      {...(isTextarea
        ? {
            multiline: true,
            rows: 4,
          }
        : {})}
      {...(maxLength
        ? {
            inputProps: { maxLength },
          }
        : {})}
    />
  );
};

export default memo(StringInput);
