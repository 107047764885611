import { TypeApiRoute } from '../../models/type.api.request';
import { TDictionaryItemResponse } from '../../models/dictionaries.model';

type TypeRequest = {
  remoteName: string;
  nameOrId: string;
};

type TypeResponse = TDictionaryItemResponse;

export const getAllDictionariesByNameOrId: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/da-dictionary/admin/dictionary/${params.remoteName}/findAll`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
