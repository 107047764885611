/* eslint-disable no-console */
import { EDictionaryItemStatus } from '../../../api/models/dictionaries.model';
import { Axios, lazyInject, provide } from '../../shared/utils';
import {
  DictionariesStore,
  EDictionaryLoadingSteps,
} from '../../stores/dictionaries/dictionaries.store';

import { TypeApiRequest } from './../../shared/utils/axios2';
import { TreeStore } from './../../stores/dictionaries/tree.store';

@provide.singleton()
export class DictionariesService {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(DictionariesStore)
  dictionariesStore: DictionariesStore;

  @lazyInject(TreeStore)
  treeStore: TreeStore;

  getAllDictionaries = () => {
    try {
      return this.axios.api
        .getAllDictionaries({})
        .then(data => {
          this.dictionariesStore.setDictionaryNameList(data.content);
          this.dictionariesStore.setRemoteNameToDictionaryName(data.content);
          this.dictionariesStore.setDictionaryLoadingStep(EDictionaryLoadingSteps.NameList);

          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error('Request all dictionaries error', error);
    }
  };

  getShallowDictionaryListByRemoteName = (remoteName: string) => {
    try {
      return this.axios.api
        .getAllDictionariesByParams(
          {
            remoteName,
            originalOnly: true,
            latestVersion: true,
            status: EDictionaryItemStatus.Active,
            level: 1,
          },
          { omit: ['remoteName'] }
        )
        .then(data => {
          this.dictionariesStore.setDictListByRemoteName(remoteName, data.content);
          this.dictionariesStore.setDictionaryLoadingStep(
            EDictionaryLoadingSteps.SelectedDictionary
          );
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error('Request all dictionaries by remoteName error', error);
    }
  };

  getChildDictionaryListByParentId = (remoteName: string, parentId: string) => {
    try {
      return this.axios.api
        .getAllDictionariesByParams(
          {
            remoteName,
            originalOnly: true,
            latestVersion: true,
            status: EDictionaryItemStatus.Active,
            parentId,
          },
          { omit: ['remoteName'] }
        )
        .then(data => {
          this.treeStore.setTreeNode(parentId, data);
          return data.content;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Request ${remoteName} ${parentId} children error`, error);
    }
  };
  getDictionaryItem = (remoteName: string, id: string) => {
    try {
      return this.axios.api
        .getDictionaryItem(
          {
            remoteName,
            id,
          },
          { omit: ['remoteName', 'id'] }
        )
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Request ${remoteName} ${id} error`, error);
    }
  };

  getDictionaryItemBreadcrumbs = (remoteName: string, id: string) => {
    try {
      return this.axios.api
        .getDictionaryItemBreadcrumbs(
          {
            remoteName,
            id,
          },
          { omit: ['remoteName', 'id'] }
        )
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Request ${remoteName} ${id} breadcrumbs error`, error);
    }
  };

  getDictionaryItemListByNameOrId = (remoteName: string, nameOrId: string) => {
    try {
      return this.axios.api
        .getAllDictionariesByNameOrId(
          {
            remoteName,
            nameOrId,
          },
          { omit: ['remoteName'] }
        )
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Request ${remoteName} ${nameOrId} error`, error);
    }
  };

  updateDictionaryItem = (payload: TypeApiRequest<'saveDictionaryItem'>) => {
    try {
      return this.axios.api
        .saveDictionaryItem(payload, { omit: ['remoteName', 'id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Update ${payload.remoteName} ${payload.id} error`, error);
    }
  };

  rewriteDictionaryItem = (payload: TypeApiRequest<'rewriteDictionaryItem'>) => {
    try {
      return this.axios.api
        .rewriteDictionaryItem(payload, { omit: ['remoteName', 'id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Rewrite ${payload.remoteName} ${payload.id} error`, error);
    }
  };

  activateDictionaryItem = (payload: TypeApiRequest<'activateDictionaryItem'>) => {
    try {
      return this.axios.api
        .activateDictionaryItem(payload, { omit: ['remoteName'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Activate ${payload.remoteName} ${payload.entityId} error`, error);
    }
  };

  archiveDictionaryItem = (payload: TypeApiRequest<'archiveDictionaryItem'>) => {
    try {
      return this.axios.api
        .archiveDictionaryItem(payload, { omit: ['remoteName'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Archive ${payload.remoteName} ${payload.id} error`, error);
    }
  };

  deleteDictionaryItem = (payload: TypeApiRequest<'deleteDictionaryItem'>) => {
    try {
      return this.axios.api
        .deleteDictionaryItem(payload, { omit: ['remoteName'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Delete ${payload.remoteName} ${payload.entityId} error`, error);
    }
  };

  createDictionaryItem = (payload: TypeApiRequest<'createDictionaryItem'>) => {
    try {
      return this.axios.api
        .createDictionaryItem(payload, { omit: ['remoteName'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Create ${payload.remoteName} ${payload.name} error`, error);
    }
  };

  fetchDictionaryAttributeList = (remoteName: string) => {
    try {
      return this.axios.api
        .getAttributesByRemoteName(
          {
            remoteName,
          },
          { omit: ['remoteName'] }
        )
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Request ${remoteName} attributes error`, error);
    }
  };

  fetchDictionariesByFilters = (
    remoteName: string,
    filterPayload: TypeApiRequest<'getAllDictionariesByParams'>
  ) => {
    try {
      return this.axios.api
        .getAllDictionariesByParams(
          {
            remoteName,
            ...filterPayload,
          },
          {
            omit: ['remoteName'],
            query: { page: filterPayload.page || 0, size: filterPayload.size || null },
          }
        )
        .then(data => {
          return data.content;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Request ${remoteName} error`, error);
    }
  };

  markDictionaryAsLeader = (payload: TypeApiRequest<'markDictionaryAsLeader'>) => {
    try {
      return this.axios.api
        .markDictionaryAsLeader(payload, { omit: ['remoteName'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Mark as leader ${payload.id} error`, error);
    }
  };

  markDictionaryAsOriginal = (payload: TypeApiRequest<'markDictionaryAsOriginal'>) => {
    try {
      return this.axios.api
        .markDictionaryAsOriginal(payload, { omit: ['remoteName'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Mark as original ${payload.id} error`, error);
    }
  };

  fetchDictionaryDependenciesList = (payload: TypeApiRequest<'getDependenciesList'>) => {
    try {
      return this.axios.api
        .getDependenciesList(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Fetch dependencies ${payload.dictionaryName} error`, error);
    }
  };

  fetchAttributeListByOwnerId = (payload: TypeApiRequest<'getAttributeListByOwnerId'>) => {
    try {
      return this.axios.api
        .getAttributeListByOwnerId(payload, { omit: ['remoteName'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Fetch attribute ${payload.remoteName}/${payload.ownerId} error`, error);
    }
  };

  createDictionaryAttribute = (payload: TypeApiRequest<'createDictionaryAttribute'>) => {
    try {
      return this.axios.api
        .createDictionaryAttribute(payload, { omit: ['remoteName'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Create dictionary attribute error`, error);
    }
  };

  getDependencyLinksByDependencyRecord = (
    payload: TypeApiRequest<'getDependencyLinksByDependencyRecord'>
  ) => {
    try {
      return this.axios.api
        .getDependencyLinksByDependencyRecord(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get dependency error`, error);
    }
  };

  getDependencyLinksByTargetRecord = (
    payload: TypeApiRequest<'getDependencyLinksByTargetRecord'>
  ) => {
    try {
      return this.axios.api
        .getDependencyLinksByTargetRecord(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get dependency error`, error);
    }
  };

  createDependency = (payload: TypeApiRequest<'createDependency'>) => {
    try {
      return this.axios.api
        .createDependency(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Create dependency error`, error);
    }
  };

  createDependencyLink = (payload: TypeApiRequest<'createDependencyLink'>) => {
    try {
      return this.axios.api
        .createDependencyLink(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Create dependencyLink error`, error);
    }
  };

  deleteDependencyLink = (payload: TypeApiRequest<'deleteDependencyLink'>) => {
    try {
      return this.axios.api
        .deleteDependencyLink(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Delete dependencyLink error`, error);
    }
  };

  cleanDictionaryCache = (payload: TypeApiRequest<'cleanDictionaryCache'>) => {
    try {
      return this.axios.api.cleanDictionaryCache(payload);
    } catch (error) {
      console.error(`Clean dependency cache error`, error);
    }
  };

  exportDictionary = (payload: TypeApiRequest<'exportDictionary'>) => {
    try {
      return this.axios.api
        .exportDictionary(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Export dictionary`, error);
    }
  };

  exportDictionaryDependencies = (payload: TypeApiRequest<'exportDictionaryDependencies'>) => {
    try {
      return this.axios.api
        .exportDictionaryDependencies(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Export dictionary dependencies`, error);
    }
  };

  importDictionaryDryRun = (payload: TypeApiRequest<'importDictionaryDryRun'>, file: FormData) => {
    try {
      return this.axios.api
        .importDictionaryDryRun(payload, {
          formData: file,
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Import dry-run dictionary error`, error);
    }
  };

  importDictionary = (payload: TypeApiRequest<'importDictionary'>, file: FormData) => {
    try {
      return this.axios.api
        .importDictionary(payload, {
          formData: file,
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Import dictionary error`, error);
    }
  };

  importDictionaryDependenciesDryRun = (
    payload: TypeApiRequest<'importDictionaryDependenciesDryRun'>,
    file: FormData
  ) => {
    try {
      return this.axios.api
        .importDictionaryDependenciesDryRun(payload, {
          formData: file,
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Import dry-run dictionary deps error`, error);
    }
  };

  importDictionaryDependencies = (
    payload: TypeApiRequest<'importDictionaryDependencies'>,
    file: FormData
  ) => {
    try {
      return this.axios.api
        .importDictionaryDependencies(payload, {
          formData: file,
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Import dry-run dictionary deps error`, error);
    }
  };
}
