import { IGetChecklistAttribute } from '../../../models/checklist.model';
import { TypeApiRoute } from '../../../models/type.api.request';

type TypeRequest = {
  id: string;
};

type TypeResponse = IGetChecklistAttribute;

type TypeError = {};

export const getAttributeInChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/checklist/attribute/${request.id}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
