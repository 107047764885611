import { makeAutoObservable, runInAction } from 'mobx';
import { uniqBy } from 'lodash';

import { provide } from '../../shared/utils';
import { IChecklist, IChecklistOrganizationItem } from '../../../api/models/checklist.model';
import { IChecklistStage } from '../../../api/models/checklist.stage.model';

import { IChecklistAttribute } from './../../../api/models/checklist.attribute.model';

export type ICurrentEditChecklistAttributeWithBaseAttribute = IChecklistAttribute & {
  defaultChecklistData?: IChecklistAttribute;
};
@provide.singleton()
export class ChecklistCRUDStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _currentChecklist: Partial<IChecklist> = null;
  private _currentChecklistOrganizationList: IChecklistOrganizationItem[] = [];
  private _currentLoadedChecklist: IChecklist = null;
  private _currentCopyChecklist: Partial<IChecklist> = null;

  private _isEditMode = true;

  private _currentEditStage: IChecklistStage & { isCreationMode?: boolean } = null;
  private _currentChecklistStageList: IChecklistStage[] = [];
  private _currentEditChecklistAttribute: ICurrentEditChecklistAttributeWithBaseAttribute = null;

  get currentChecklist() {
    return this._currentChecklist;
  }

  get currentChecklistOrganizationList() {
    return this._currentChecklistOrganizationList;
  }

  get isEditMode() {
    return this._isEditMode;
  }

  get currentEditStage() {
    return this._currentEditStage;
  }

  get currentChecklistStageList() {
    return this._currentChecklistStageList;
  }

  get currentEditChecklistAttribute() {
    return this._currentEditChecklistAttribute;
  }

  get currentLoadedChecklist() {
    return this._currentLoadedChecklist;
  }

  get currentCopyChecklist() {
    return this._currentCopyChecklist;
  }

  setCurrentChecklist = (data: IChecklist) => {
    this._currentChecklist = data;
  };

  setCurrentChecklistOrganizationList = (data: IChecklistOrganizationItem[]) => {
    this._currentChecklistOrganizationList = data;
  };

  setEditMode = (state: boolean) => {
    this._isEditMode = state;
  };

  setCurrentEditStage = (stage: IChecklistStage) => {
    this._currentEditStage = stage;
  };

  setCurrentChecklistStageList = (stageList: IChecklistStage[]) => {
    this._currentChecklistStageList = stageList;
  };

  setCurrentEditChecklistAttribute = (data: ICurrentEditChecklistAttributeWithBaseAttribute) => {
    this._currentEditChecklistAttribute = data;
  };

  setCurrentLoadedChecklist = (checklist: IChecklist) => {
    this._currentLoadedChecklist = checklist;
  };

  setCurrentCopyChecklist = (checklist: IChecklist) => {
    this._currentCopyChecklist = checklist;
  };

  changeCurrentChecklistValue = <K extends keyof IChecklist>(field: K, value: IChecklist[K]) => {
    runInAction(() => {
      this._currentChecklist = { ...this.currentChecklist, [field]: value };
    });
  };

  changeCurrentEditStage = <K extends keyof typeof this._currentEditStage>(
    field: K,
    value: typeof this._currentEditStage[K]
  ) => {
    runInAction(() => {
      this._currentEditStage = { ...this._currentEditStage, [field]: value };
    });
  };

  changeCurrentEditChecklistAttribute = <
    K extends keyof typeof this._currentEditChecklistAttribute
  >(
    field: K,
    value: typeof this._currentEditChecklistAttribute[K]
  ) => {
    runInAction(() => {
      this._currentEditChecklistAttribute = {
        ...this._currentEditChecklistAttribute,
        [field]: value,
      };
    });
  };

  changeCurrentCopyChecklistValue = <K extends keyof IChecklist>(
    field: K,
    value: IChecklist[K]
  ) => {
    runInAction(() => {
      this._currentCopyChecklist = { ...this.currentChecklist, [field]: value };
    });
  };

  addStageToCurrentChecklistStageList = (stage: IChecklistStage) => {
    this._currentChecklistStageList = uniqBy([...this._currentChecklistStageList, stage], 'id');
  };

  clearCurrentEditChecklistAttribute = () => {
    this._currentEditChecklistAttribute = null;
  };

  clearCurrentEditStage = () => {
    this._currentEditStage = null;
  };

  clearCurrentChecklistOrganizationList = () => {
    this._currentChecklistOrganizationList = [];
  };

  clearCurrentChecklist = () => {
    this._currentChecklist = null;
  };
}
