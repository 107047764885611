import { TFileEntity } from '../../modules/shared/types/requests';

export type TDictionaryName = {
  title: string;
  remoteName: string;
};

export type TDictionaryNameList = {
  content: TDictionaryName[];
};

export type TDictionaryItemResponse = {
  totalPages: number;
  totalElements: number;
  size: number;
  content: TDictionaryItem[];
};

export enum EDictionaryItemStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type TDictionaryItem = {
  parentId?: string;
  code: string;
  description?: string;
  name: string;
  originalLink: string;
  picLink?: TFileEntity | string;
  status?: EDictionaryItemStatus;
  level: number;
  id: string;
  version?: number;
  path?: string;
  hasChild?: boolean;
  attrs?: Record<string, any>;
  authorFullName?: string;
  editDate?: string;
  original?: boolean;
  isChild?: boolean;
  isDouble?: boolean;
};

export type TDictionaryItemCrumbs = {
  crumbs: Array<TDictionaryItemCrumb>;
  entity: TDictionaryItem;
};

export type TDictionaryItemCrumb = {
  code: string;
  id: string;
  name: string;
  picLink?: TFileEntity;
};

export interface IDictionaryExportOptions {
  idIn?: string[];
  status?: EDictionaryItemStatus;
  originalOnly?: boolean;
}
