import { FC } from 'react';

import Styled from './styled';

const FormRow: FC = ({ children }) => {
  const isEmpty = !children || (Array.isArray(children) && children.filter(Boolean).length === 0);

  return !isEmpty ? <Styled.FormRow>{children}</Styled.FormRow> : null;
};

export default FormRow;
