import { Typography } from '@mui/material';
import styled from 'styled-components';

import { Colors } from '../../../../../../../../../../constants/colors';

const Title = styled(Typography)`
  font-weight: bold;
`;

const Subtitle = styled(Typography)`
  color: ${Colors.gray};
  font-size: 14px;
  font-weight: 400;
  max-width: 80%;
`;

const RequiredSign = styled(Typography)`
  color: ${Colors.red};
  font-weight: bold;
`;

const ActionButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 20%;
  display: flex;
`;

const Styled = {
  Title,
  Subtitle,
  RequiredSign,
  ActionButtonsWrapper,
};

export default Styled;
