import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useModal } from '@farmlink/farmik-ui';
import { orderBy } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { IChecklistStage } from '../../../../../../../../../../api/models/checklist.stage.model';
import { useStore } from '../../../../../../../../../shared/utils';
import { ChecklistCRUDController } from '../../../../../../../../../controllers/checklist.CRUD.controller';
import { CREATE_STAGE_MODAL } from '../../modals';
import { CREATE_ATTRIBUTE_MODAL } from '../../modals/configs/createAttributeModal';
import { ChecklistsAttributeController } from '../../../../../../../../../controllers/checklistAttribute.controller';
import { IGetChecklistAttribute } from '../../../../../../../../../../api/models/checklist.model';
import Attribute from '../Attribute/Attribute';
import AdornmentButtons from '../AdornmentButtons/AdornmentButtons';
import { EChecklistAttributeType } from '../../../../../../../../../../api/models/checklist.attribute.model';
import { ChecklistAttributesStore } from '../../../../../../../../../stores/checkLists/attributes/checklistAttributes.store';

import Styled from './styled';

interface IProps {
  stageData?: IChecklistStage;
  nested?: {
    attributes: IGetChecklistAttribute[];
    id: string;
  };
}

const Stage: FC<IProps> = observer(({ stageData, nested }) => {
  const { setCurrentStageData, currentChecklistPropertySwitches, deleteChecklistStage } = useStore(
    ChecklistCRUDController
  );
  const {
    getChecklistAttributeList,
    clearCurrentChecklistAttributeEdit,
    addAttributeListToStage,
    checklistAttributeListByStageId,
  } = useStore(ChecklistsAttributeController);
  const { addCurrentChecklistBasicAttributeIdListByStage } = useStore(ChecklistAttributesStore);
  const { openModalByModalId } = useModal();

  const [isLoading, setIsLoading] = useState(false);

  const stageId = stageData?.id || nested?.id;

  const attributeList = useMemo(() => toJS(checklistAttributeListByStageId).get(stageId), [
    toJS(checklistAttributeListByStageId),
  ]);

  useEffect(() => {
    if (nested) {
      addAttributeListToStage(nested.id, nested.attributes);
      return;
    }

    setIsLoading(true);

    getChecklistAttributeList({ stageId })
      .then(data => {
        data.content
          .filter(item => item.attribute.type === EChecklistAttributeType.ChecklistInstanceLink)
          .forEach(item => {
            return getChecklistAttributeList({
              checkListId: item.attribute.checklistLink,
            }).then(response => {
              addAttributeListToStage(
                stageId,
                response.content.map(attribute => ({ ...attribute, parentId: item.id }))
              );
            });
          });

        addAttributeListToStage(stageId, data.content);

        addCurrentChecklistBasicAttributeIdListByStage(
          stageId,
          data.content
            .filter(attribute => attribute?.stageId === stageId && attribute?.isActive)
            .flatMap(attribute => attribute.attribute.id)
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const parentMap = useMemo(() => {
    const newParentMap = new Map<string, IGetChecklistAttribute[]>();

    attributeList?.forEach(attribute => {
      if (attribute?.parentId) {
        const childArr = newParentMap.get(attribute.parentId) || [];

        newParentMap.set(attribute.parentId, [...childArr, attribute]);
      }
    });

    return newParentMap;
  }, [attributeList]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Styled.TitleGroup>
          <Typography
            fontWeight="bold"
            maxWidth="92%"
          >{`№${stageData?.order}, ${stageData?.name}`}</Typography>
          <AdornmentButtons
            onDeleteClick={() => deleteChecklistStage(stageData?.id)}
            onEditClick={() => {
              setCurrentStageData(stageData);
              openModalByModalId(CREATE_STAGE_MODAL);
            }}
            hideDelete={currentChecklistPropertySwitches?.nested}
            hideLockToggler
            disableDelete={attributeList?.length > 0}
          />
        </Styled.TitleGroup>
      </AccordionSummary>
      <AccordionDetails>
        <Divider />
        <Button
          onClick={() => {
            clearCurrentChecklistAttributeEdit();
            setCurrentStageData(stageData);

            openModalByModalId(CREATE_ATTRIBUTE_MODAL);
          }}
          variant="text"
        >
          + Добавить атрибут
        </Button>
        {orderBy(attributeList, ['order', 'id']).map(attribute => {
          if (!attribute?.parentId) {
            return (
              <Attribute
                data={attribute}
                nestedIndex={0}
                key={attribute.id}
                parentMap={parentMap}
              />
            );
          } else {
            return null;
          }
        })}
      </AccordionDetails>
    </Accordion>
  );
});

export default Stage;
