import { Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { ISelectOption } from '../../../../../../types/selectOption';
import { CheckListsController } from '../../../../../controllers/check.list.controller';
import { useStore } from '../../../../../shared/utils';

import { SelectOrgByNameOrInn } from './components';
import Styled from './styled';

enum EFileImportStage {
  Idle,
  DryRun,
  DryRunError,
  DryRunSuccess,
  Import,
  ImportSuccess,
  ImportError,
}

const ChecklistExportModalContainer = () => {
  const { importChecklistDryRun, importChecklist } = useStore(CheckListsController);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOption>(null);
  const [fileImportStage, setFileImportStage] = useState<EFileImportStage>(EFileImportStage.Idle);
  const [output, setOutput] = useState<string | null>(null);

  const { closeModal } = useModal();

  const onAddFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files[0]);
  }, []);

  const handleSubmit = event => {
    event.preventDefault();

    setOutput(null);

    const formData = new FormData();
    formData.append('source', selectedFile);

    if (fileImportStage < 3) {
      importChecklistDryRun(
        {
          organizationId: selectedOrganization.value,
          createNewBaseAttributes: event?.target?.createNewBaseAttributes?.checked,
          createNewCheckLists: event?.target?.createNewCheckLists?.checked,
        },
        formData
      )
        .then(data => {
          setOutput(data);
          setFileImportStage(EFileImportStage.DryRunSuccess);
        })
        .catch(data => {
          setOutput(data.response.data);
          setFileImportStage(EFileImportStage.DryRunError);
        });
    } else {
      importChecklist(
        {
          organizationId: selectedOrganization.value,
          createNewBaseAttributes: event?.target?.createNewBaseAttributes?.checked,
          createNewCheckLists: event?.target?.createNewCheckLists?.checked,
        },
        formData
      )
        .then(data => {
          setOutput(data);
          setFileImportStage(EFileImportStage.ImportSuccess);
        })
        .catch(data => {
          setOutput(data.response.data);
          setFileImportStage(EFileImportStage.ImportError);
        });
    }
  };

  const isDisabledDryRun = selectedFile === null || selectedOrganization === null;
  const isError =
    fileImportStage === EFileImportStage.DryRunError ||
    fileImportStage === EFileImportStage.ImportError;

  return (
    <Styled.Wrapper onSubmit={handleSubmit}>
      <Styled.InputRow position={'relative'}>
        <Styled.CloseButton onClick={closeModal}>Закрыть</Styled.CloseButton>
      </Styled.InputRow>
      <Styled.InputRow>
        <TextField value={selectedFile?.name} InputProps={{ readOnly: true }} fullWidth />
        <Button sx={{ width: '240px' }} variant="contained" component="label">
          Выбрать файл
          <input type="file" name="file" onChange={onAddFile} accept="application/JSON" hidden />
        </Button>
      </Styled.InputRow>
      <Styled.InputRow>
        <SelectOrgByNameOrInn
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
        />
      </Styled.InputRow>
      <Styled.InputRow>
        <FormControlLabel
          control={<Switch id="createNewCheckLists" />}
          label="Создать новый чек-лист"
          name="createNewCheckLists"
        />
      </Styled.InputRow>
      <Styled.InputRow>
        <FormControlLabel
          control={<Switch id="createNewBaseAttributes" />}
          label="Создать новый базовый атрибут"
          name="createNewBaseAttributes"
        />
      </Styled.InputRow>

      {fileImportStage === EFileImportStage.DryRunError ||
      fileImportStage === EFileImportStage.DryRunSuccess ? (
        <>
          <span>Статус проверки файла: </span>
          <Styled.ImportStatus $isError={isError}>
            {isError ? 'Ошибка' : 'Успех'}
          </Styled.ImportStatus>
        </>
      ) : null}

      {fileImportStage === EFileImportStage.ImportError ||
      fileImportStage === EFileImportStage.ImportSuccess ? (
        <>
          <span>Статус импортирования файла: </span>
          <Styled.ImportStatus $isError={isError}>
            {isError ? 'Ошибка' : 'Успех'}
          </Styled.ImportStatus>
        </>
      ) : null}

      {fileImportStage > 1 && output?.length && (
        <Styled.Output>
          <Typography>{output}</Typography>
        </Styled.Output>
      )}

      <Styled.InputRow>
        {(fileImportStage === EFileImportStage.Idle ||
          fileImportStage === EFileImportStage.DryRunError ||
          fileImportStage >= 3) &&
        fileImportStage !== EFileImportStage.ImportSuccess ? (
          <Button
            size="large"
            fullWidth
            disabled={isDisabledDryRun}
            type="submit"
            variant="contained"
          >
            {fileImportStage >= 3 ? 'Сохранить' : 'Импортировать'}
          </Button>
        ) : (
          <Button size="large" fullWidth onClick={closeModal} variant="contained">
            Закрыть
          </Button>
        )}
      </Styled.InputRow>
    </Styled.Wrapper>
  );
};

export default ChecklistExportModalContainer;
