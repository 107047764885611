import { Autocomplete, Button, TextField } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';

import {
  EAttributeUnit,
  IAttributeItem,
} from '../../../../../../../../../../../../../../../../../../api/models/attribute.model';
import { ISelectOption } from '../../../../../../../../../../../../../../../../../../types/selectOption';
import { TAttributeWithValue } from '../../../../../../../../../../../../../../../../../stores';
import { SelectNameOrId } from '../../../../../../../SelectNameOrId';

import Styled from './styled';

interface IProps {
  paramData: TAttributeWithValue;
  clientId: string;
  removeParamHandler: (id: string) => void;
  changeAttribute: (id: string, newAttribute: IAttributeItem) => void;
  saveAttribute: (id: string) => Promise<Response>;
  isDisableAddToParent?: boolean;
}

const NewParam: FC<IProps> = ({
  paramData,
  removeParamHandler,
  clientId,
  changeAttribute,
  saveAttribute,
  isDisableAddToParent,
}) => {
  const [isAddParent, setIsAddParent] = useState(false);
  const [error, setError] = useState('');

  const unitList = useMemo<ISelectOption[]>(
    () => Object.values(EAttributeUnit).map(value => ({ label: value, value })),
    []
  );

  const removeParam = useCallback(() => {
    removeParamHandler(clientId);
  }, [clientId]);

  const handleChange = (key: string, value: string) => {
    setError('');

    changeAttribute(clientId, { ...paramData, [key]: value });
  };

  const saveHandler = () => {
    if (!paramData.code || !paramData.name || !paramData.units) {
      setError('Все поля обязательны для заполнения');
      return;
    }

    if (isAddParent && !paramData.ownerId) {
      setError('Выберите родительский элемент или уберите тип на родителя');
      return;
    }

    saveAttribute(clientId).catch(reason => setError(reason));
  };

  return (
    <div>
      <Styled.Row>
        <Autocomplete
          fullWidth
          options={unitList}
          disableClearable
          onChange={(event, option: ISelectOption) => {
            handleChange('units', option.value);
          }}
          renderInput={params => <TextField {...params} placeholder="Выберите тип атрибута" />}
        />
        <TextField
          value={paramData.code}
          placeholder="Введите код доп.атрибута. Например: useInCalc"
          fullWidth
          onChange={event => {
            handleChange('code', event.target.value);
          }}
        />
        <TextField
          value={paramData.name}
          placeholder="Введите наименование доп.атрибута. Например: Параметр"
          fullWidth
          onChange={event => {
            handleChange('name', event.target.value);
          }}
        />
      </Styled.Row>

      {isAddParent && (
        <Styled.Row>
          Родительская запись:
          <SelectNameOrId
            value={paramData.ownerName}
            setFieldValue={(field, value) => {
              handleChange('ownerId', value);
            }}
            name="ownerName"
          />
        </Styled.Row>
      )}
      <Styled.Row>
        {isDisableAddToParent ? null : (
          <Button
            size="small"
            onClick={() => {
              setIsAddParent(!isAddParent);
              setError('');
            }}
          >{`+ ${isAddParent ? 'Убрать' : 'Добавить'} тип на родителя`}</Button>
        )}

        <Styled.ButtonGroup>
          <Button variant="contained" onClick={saveHandler}>
            Сохранить
          </Button>
          <Button onClick={removeParam} variant="contained">
            Отменить
          </Button>
        </Styled.ButtonGroup>
      </Styled.Row>
      <Styled.ErrorText visibility={Boolean(error)}>{error}</Styled.ErrorText>
    </div>
  );
};

export default NewParam;
