import styled from 'styled-components';

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 8px;
`;

const Styled = {
  FormRow,
};

export default Styled;
