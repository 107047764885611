import { IGetChecklistAttribute } from '../../../models/checklist.model';
import { TypeApiRoute } from '../../../models/type.api.request';
import { EAttributeInChecklistDependencyType } from '../../../models/checklist.attribute.model';

type TypeRequest = {
  id: string;
  order?: number;
  isRequired?: boolean;
  isActive?: boolean;
  isTitle?: boolean;
  displayParent?: boolean;
  fileRequired?: boolean;
  toolTip?: string;
  parentId?: string;
  dependencyType?: EAttributeInChecklistDependencyType;
  dependencyAttributeId?: string;
  min?: number;
  max?: number;
  normal?: number;
  stageId?: string;
};

type TypeResponse = IGetChecklistAttribute;

type TypeError = {};

export const changeAttributeInChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/checklist/attribute/${request.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
