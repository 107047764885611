import { Axios, TypeApiRequest, lazyInject, provide } from '../../shared/utils';

@provide.singleton()
export class ReportService {
  @lazyInject(Axios)
  protected axios: Axios;

  getReportPowerBIWeather = async (payload: TypeApiRequest<'getReportPowerBIWeather'>) => {
    try {
      const response = await this.axios.api.getReportPowerBIWeather(payload);

      return response;
    } catch (error) {
      console.log('generate report "Погодавполе" error');
    }
  };

  getReportsConfigByOrgId = async (payload: TypeApiRequest<'getReportsConfigByOrgId'>) => {
    try {
      const response = await this.axios.api.getReportsConfigByOrgId(payload, { omit: ['id'] });

      return response;
    } catch (error) {
      console.log('get reports config error');
    }
  };

  createReportConfig = async (payload: TypeApiRequest<'createReportConfig'>) => {
    try {
      const response = await this.axios.api.createReportConfig(payload);

      return response;
    } catch (error) {
      console.log('create reports config error');
      return Promise.reject(error);
    }
  };

  updateReportConfig = async (payload: TypeApiRequest<'updateReportConfig'>) => {
    try {
      const response = await this.axios.api.updateReportConfig(payload, { omit: ['id'] });

      return response;
    } catch (error) {
      console.log('update reports config error');
      return Promise.reject(error);
    }
  };

  deleteReportConfig = async (payload: TypeApiRequest<'deleteReportConfig'>) => {
    try {
      const response = await this.axios.api.deleteReportConfig(payload, { omit: ['id'] });

      return response;
    } catch (error) {
      console.log('delete reports config error');
      return Promise.reject(error);
    }
  };

  triggerDataReport = async (payload: TypeApiRequest<'triggerDataReport'>) => {
    try {
      const response = await this.axios.api.triggerDataReport(payload);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  triggerFilesReport = async (payload: TypeApiRequest<'triggerFilesReport'>) => {
    try {
      const response = await this.axios.api.triggerFilesReport(payload);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  downloadAgrosignalReport = async (payload: TypeApiRequest<'downloadAgrosignalReport'>) => {
    try {
      const response = await this.axios.api.downloadAgrosignalReport(payload);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getReportImportOperationList = async (
    payload: TypeApiRequest<'getReportImportOperationList'>
  ) => {
    try {
      const response = await this.axios.api.getReportImportOperationList(payload, {
        omit: ['page', 'size'],
        query: { page: payload.page, size: payload.size },
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getPogodaVPoleReportImportOperationList = async (
    payload: TypeApiRequest<'getPogodaVPoleReportImportOperationList'>
  ) => {
    try {
      const response = await this.axios.api.getPogodaVPoleReportImportOperationList(payload, {
        omit: ['page', 'size'],
        query: { page: payload.page, size: payload.size },
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /* Cropwise */

  getCropwiseReportsConfigByOrgId = async (
    payload: TypeApiRequest<'getCropwiseReportsConfigByOrgId'>
  ) => {
    try {
      const response = await this.axios.api.getCropwiseReportsConfigByOrgId(payload, {
        omit: ['id'],
      });

      return response;
    } catch (error) {
      console.log('get reports config error');
    }
  };

  createCropwiseReportConfig = async (payload: TypeApiRequest<'createCropwiseReportConfig'>) => {
    try {
      const response = await this.axios.api.createCropwiseReportConfig(payload);

      return response;
    } catch (error) {
      console.log('create reports config error');
      return Promise.reject(error);
    }
  };

  updateCropwiseReportConfig = async (payload: TypeApiRequest<'updateCropwiseReportConfig'>) => {
    try {
      const response = await this.axios.api.updateCropwiseReportConfig(payload, { omit: ['id'] });

      return response;
    } catch (error) {
      console.log('update reports config error');
      return Promise.reject(error);
    }
  };

  deleteCropwiseReportConfig = async (payload: TypeApiRequest<'deleteCropwiseReportConfig'>) => {
    try {
      const response = await this.axios.api.deleteCropwiseReportConfig(payload, { omit: ['id'] });

      return response;
    } catch (error) {
      console.log('delete reports config error');
      return Promise.reject(error);
    }
  };

  downloadCropwiseReport = async (payload: TypeApiRequest<'downloadCropwiseReport'>) => {
    try {
      const response = await this.axios.api.downloadCropwiseReport(payload);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  toggleCropwiseAutoReportUpload = async (id: string, state: boolean) => {
    try {
      if (state) {
        const response = await this.axios.api.enableCropwiseAutoReportsAutoUpload(
          { id },
          {
            omit: ['id'],
          }
        );

        return response;
      } else {
        const response = await this.axios.api.disableCropwiseAutoReportsAutoUpload(
          { id },
          {
            omit: ['id'],
          }
        );

        return response;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getCropwiseReportImportOperationList = async (
    payload: TypeApiRequest<'getCropwiseReportImportOperationList'>
  ) => {
    try {
      const response = await this.axios.api.getCropwiseReportImportOperationList(payload, {
        omit: ['page', 'size'],
        query: { page: payload.page, size: payload.size },
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getAgropogodaReportPowerBIWeather = async (
    payload: TypeApiRequest<'getAgropogodaReportPowerBIWeather'>
  ) => {
    try {
      const response = await this.axios.api.getAgropogodaReportPowerBIWeather(payload);

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
