import { IGetChecklistAttribute } from '../../../models/checklist.model';
import { TypeApiRoute } from '../../../models/type.api.request';

import { IAttributeInChecklist } from './../../../models/checklist.attribute.model';

type TypeRequest = IAttributeInChecklist & { checkListId: string };

type TypeResponse = IGetChecklistAttribute;

type TypeError = {};

export const createAttributeInChecklist: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/admin/checklist/attribute/`,
  method: 'POST',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
