import { useModal, useNotificator } from '@farmlink/farmik-ui';
import { Button, IconButton, Tab, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { isNil, maxBy, omitBy } from 'lodash';
import moment from 'moment';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReplyIcon from '@mui/icons-material/Reply';
import { generatePath } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  EAttributeInChecklistDependencyType,
  EChecklistAttributeType,
  IAttributeInChecklist,
  IChecklistAttribute,
} from '../../../../../../../../../../../api/models/checklist.attribute.model';
import { IGetChecklistAttribute } from '../../../../../../../../../../../api/models/checklist.model';
import { ChecklistCRUDController } from '../../../../../../../../../../controllers/checklist.CRUD.controller';
import { ChecklistsAttributeController } from '../../../../../../../../../../controllers/checklistAttribute.controller';
import { DictionariesController } from '../../../../../../../../../../controllers/dictionaries.controller';
import { deepObjectsDiff, useStore } from '../../../../../../../../../../shared/utils';
import { FormInput, FormRow } from '../../../../../../../common';
import { IFormInputAutocompleteConfig } from '../../../../../../../common/AttributeBuilderForm/inputs/FormInput/FormInput';
import {
  attributeInChecklistFormItemsScheme,
  checklistsAttributeBuilderSchemeMap,
} from '../../../AttributeBuilderForm.config';
import { ISelectOptionExtended } from '../../../../../../../../../../../types/selectOption';
import { Colors } from '../../../../../../../../../../../constants/colors';
import { AdminRoutes } from '../../../../../../../../../routes';
import { ChecklistAttributesStore } from '../../../../../../../../../../stores/checkLists/attributes/checklistAttributes.store';

import { FileSubform } from './components';
import UserDictionaryLinkTab from './components/UserDictionaryLinkTab/UserDictionaryLinkTab';

type TFormikDataType = Partial<IAttributeInChecklist> & {
  type?: EChecklistAttributeType;
  id?: string;
  calculationType?: string;
  checklistLink?: string;
  checkListId: string;
  order: number;
  name?: string | ISelectOptionExtended;
  isMultiselect?: boolean;
};

const CreateAttributeModal = observer(() => {
  const { closeModal } = useModal();
  const { setNotification } = useNotificator();

  const {
    fetchChecklistStageList,
    currentChecklistPropertySwitches,
    currentChecklist,
    stageData,
    getChecklistById,
  } = useStore(ChecklistCRUDController);
  const { getAllDictionaries } = useStore(DictionariesController);
  const {
    currentChecklistAttributeEdit,
    checklistAttributeListByStageId,
    addAttributeListToStage,
    addChecklistAttributeListWithoutStage,
    changeAttributeInChecklist,
    clearCurrentChecklistAttributeEditFileList,
    checklistAttributeListByAllStages,
    checklistAttributeListWithoutStage,
    fetchCurrentChecklistAttributeUserDictionaryLinkValueList,
    getActiveChecklistAttributeList,
    getChecklistAttributeList,
    createAttributeInChecklist,
    getAttributeInChecklist,
  } = useStore(ChecklistsAttributeController);
  const {
    currentChecklistBasicAttributeIdListByStage,
    getExistsBasicAttributeIdListInStage,
    addCurrentChecklistBasicAttributeIdListByStage,
  } = useStore(ChecklistAttributesStore);

  const [defaultAttribute, setDefaultAttribute] = useState<IChecklistAttribute>(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tab, setTab] = useState('1');

  const {
    id,
    attribute,
    dependencyAttributeId,
    displayParent,
    dependencyType,
    fileRequired,
    isActive,
    isRequired,
    isTitle,
    max,
    min,
    normal,
    order,
    parentId,
    toolTip,
  } = currentChecklistAttributeEdit || {};

  const maxOrder = useMemo(
    () =>
      currentChecklist?.nested
        ? maxBy(checklistAttributeListWithoutStage.flatMap(item => item?.order || 0))
        : maxBy(checklistAttributeListByAllStages.flatMap(item => item?.order || 0)) || 0,
    [
      checklistAttributeListByStageId,
      checklistAttributeListByAllStages,
      checklistAttributeListWithoutStage,
    ]
  );

  const isBasicAttributeExisted = useCallback(
    (basicId: string) =>
      getExistsBasicAttributeIdListInStage(stageData?.id ?? 'noStage')?.has(basicId),
    [stageData, currentChecklistBasicAttributeIdListByStage]
  );

  const initialValues = useMemo<TFormikDataType>(
    () => ({
      id: id || null,
      attributeId: attribute?.id || null,
      checkListId: currentChecklist?.id || null,
      dependencyAttributeId: dependencyAttributeId || null,
      dependencyType: (dependencyType as EAttributeInChecklistDependencyType) || null,
      displayParent: displayParent || null,
      fileRequired: fileRequired || null,
      isActive: isActive || null,
      isRequired: isRequired || null,
      isTitle: isTitle || null,
      max: max || null,
      min: min || null,
      normal: normal || null,
      order: isNil(order) && order !== '' ? maxOrder + 1 : order,
      parentId: parentId || null,
      stageId: stageData?.id || currentChecklistAttributeEdit?.stageId || null,
      stage: stageData?.name || null,
      toolTip: toolTip || null,
      type: attribute?.type || null,
      name: attribute?.name || null,
      calculationType: null,
      checklistLink: attribute?.checklistLink || null,
      isMultiselect: Boolean(attribute?.isMultiselect),
    }),
    []
  );

  const isDefault = currentChecklistPropertySwitches?.default;
  const isEditMode = Boolean(id);
  const isDisplayUserDictionaryLinkParams =
    isEditMode && attribute.type === EChecklistAttributeType.UserDictionaryLink;

  const formik = useFormik<TFormikDataType>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (formData, { setSubmitting }) => {
      // TODO Sanitize formData

      setErrorMessage(null);
      if (isEditMode) {
        const changedData = deepObjectsDiff(formData, initialValues);

        changeAttributeInChecklist(
          { ...changedData, id, order: values?.order },
          formData.type,
          setNotification
        )
          .then(data => {
            if (data?.stageId) {
              addAttributeListToStage(data?.stageId, [data] as IGetChecklistAttribute[]);
              addCurrentChecklistBasicAttributeIdListByStage(data.stageId, [data.attribute.id]);
            }

            closeModal();
          })
          .catch((e: (Error | AxiosError) & { cause: any }) => {
            if (e.cause) {
              setFieldError(e.cause as string, e.message);
            } else {
              setErrorMessage((e as AxiosError)?.response?.data?.message || e.message);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        const purifiedData = omitBy(formData, item => isNil(item)) as TFormikDataType;

        if (stageData?.id) {
          purifiedData.stageId = stageData.id;
        }

        purifiedData.checkListId = currentChecklist.id;

        createAttributeInChecklist(purifiedData)
          ?.then(data => {
            if (stageData?.id) {
              addAttributeListToStage(stageData.id, [data] as IGetChecklistAttribute[]);
              addCurrentChecklistBasicAttributeIdListByStage(stageData.id, [data.attribute.id]);
              closeModal();
            } else {
              addChecklistAttributeListWithoutStage([data]);
              addCurrentChecklistBasicAttributeIdListByStage('noStage', [data.attribute.id]);
              closeModal();
            }
          })
          .catch((e: (Error | AxiosError) & { cause: any }) => {
            if (e.cause) {
              setFieldError(e.cause as string, e.message);
            } else {
              setErrorMessage((e as AxiosError)?.response?.data?.message || e.message);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  const { submitForm, setFieldValue, values, resetForm, setFieldError, setValues } = formik;

  const selectedAttributeScheme = useMemo(
    () => (values?.type ? checklistsAttributeBuilderSchemeMap[values.type] : new Set()),
    [values?.type]
  );

  useEffect(() => {
    if (
      currentChecklistAttributeEdit?.attribute?.type === EChecklistAttributeType.UserDictionaryLink
    ) {
      fetchCurrentChecklistAttributeUserDictionaryLinkValueList({
        attributeId: currentChecklistAttributeEdit.attribute.id,
        organizationId: currentChecklist.organizations[0]?.organizationId,
      });
    }

    if (currentChecklistAttributeEdit?.attribute?.type === EChecklistAttributeType.DictionaryLink) {
      const remoteName = currentChecklistAttributeEdit?.attribute?.dictionaryLink;

      getAllDictionaries().then(data => {
        setFieldValue('dictionaryLink', remoteName);
        setFieldValue(
          'dictionaryLinkAlias',
          data.content.find(dictItem => dictItem.remoteName === remoteName)?.title || ''
        );
      });
    }

    if (currentChecklistAttributeEdit?.parentId) {
      getAttributeInChecklist({ id: currentChecklistAttributeEdit?.parentId }).then(response => {
        if (response?.attribute) {
          setFieldValue(
            'parentAttribute',
            `${response.attribute.name} / ${currentChecklistAttributeEdit?.parentId}`
          );
        }
      });
    }

    if (currentChecklistAttributeEdit?.attribute?.checklistLink) {
      getChecklistById(currentChecklistAttributeEdit?.attribute?.checklistLink).then(resp => {
        setFieldValue('nestedChecklistName', resp.name);
      });
    }
  }, [currentChecklistAttributeEdit]);

  // Убрал поддержку мультиселекта H15-4163
  // const isMultiselectAvailableByCalculationTypeDisabled =
  //   selectedAttributeScheme.has('isMultiselect') &&
  //   selectedAttributeScheme.has('calculationType') &&
  //   values?.calculationType === null;

  const onSelectBaseAttribute = useCallback(
    item => {
      resetForm();

      if (item?.rawData) {
        if (isBasicAttributeExisted(item.rawData.id)) {
          setFieldError('name', 'Такой базовый атрибут уже имеется в этом стейдже');
          return;
        }

        const defaultData = { ...stageData, ...item?.rawData };

        delete defaultData.id;

        setValues(defaultData);

        setFieldValue('type', item.rawData.type);
        setFieldValue('attributeId', item.rawData.id);
        setFieldValue('order', maxOrder + 1);

        if (stageData?.id) {
          setFieldValue('stageId', stageData?.id);
        }

        setDefaultAttribute(item.rawData);

        if (
          item.rawData.type === EChecklistAttributeType.DictionaryLink &&
          item?.rawData?.dictionaryLink
        ) {
          const remoteName = item.rawData.dictionaryLink;

          getAllDictionaries().then(data => {
            setFieldValue('dictionaryLink', remoteName);
            setFieldValue(
              'dictionaryLinkAlias',
              data.content.find(dictItem => dictItem.remoteName === remoteName)?.title || ''
            );
          });
        }
      }
    },
    [values, stageData, isBasicAttributeExisted]
  );

  const dependencyTypeAttributeConfig = useMemo<IFormInputAutocompleteConfig>(() => {
    const depType = values?.dependencyType;

    const defaultDepAttrConfig: IFormInputAutocompleteConfig = {
      optionsFetchHandler: getChecklistAttributeList,
      isOnMountOptionsFetch: true,
      value: values?.dependencyAttributeId,
      searchingKey: 'attributeName',

      onSelect: item => {
        setFieldValue('dependencyAttributeId', (item.rawData as IGetChecklistAttribute).id);
        setFieldValue('dependencyTypeAttribute', item.label);
      },

      optionsMappingScheme: (item: IGetChecklistAttribute) => {
        if (item.id === currentChecklistAttributeEdit?.id) {
          return null;
        } else {
          return {
            label: `${item.attribute.name} / ${item.attribute.id}`,
            value: item.attribute.id,
            rawData: item,
          };
        }
      },

      searchingParams: {
        checklistId: values.checkListId,
        activeOnly: true,
        attributeType: 'DICTIONARY_LINK',
        value: values?.dependencyAttributeId,
        ...(values.stageId && { stageId: values.stageId }),
      },
    };

    switch (depType) {
      case 'CHECKLIST_ATTRIBUTE':
        setFieldValue('dependencyTypeAttribute', '');
        setFieldValue('dependencyAttributeAlias', null);

        return { ...defaultDepAttrConfig, reFetchOnUpdate: true };

      case 'CULTURE_TASK':
        setFieldValue('dependencyTypeAttribute', 'culture');
        setFieldValue('dependencyType', 'CULTURE_TASK');
        setFieldValue('dependencyAttributeAlias', 'Культура');
        return { ...defaultDepAttrConfig, value: 'culture', disabled: true };
      case 'PHENOPHASE_TASK':
        setFieldValue('dependencyTypeAttribute', 'phenophase');
        setFieldValue('dependencyType', 'PHENOPHASE_TASK');
        setFieldValue('dependencyAttributeAlias', 'Фенофаза');
        return {
          ...defaultDepAttrConfig,
          value: 'phenophase',
          disabled: true,
        };
      case 'OPERATION_TYPE_TASK':
        setFieldValue('dependencyTypeAttribute', 'techOperationType');
        setFieldValue('dependencyType', 'OPERATION_TYPE_TASK');
        setFieldValue('dependencyAttributeAlias', 'Тип операции');
        return {
          ...defaultDepAttrConfig,
          value: 'techOperationType',
          disabled: true,
        };

      default:
        break;
    }
  }, [values?.dependencyType, values?.checkListId, values?.stageId, defaultAttribute]);

  const useDefaultAttributeValue = useCallback(
    (fieldName: 'toolTip' | 'placeholder') => {
      if (defaultAttribute || attribute) {
        setFieldValue(fieldName, defaultAttribute?.[fieldName] || attribute?.[fieldName] || '');
      }
    },
    [attribute, defaultAttribute]
  );

  const handleChangeTab = (event: SyntheticEvent, tabId: string) => {
    setTab(tabId);
  };

  const handleOpenBaseAttribute = useCallback(() => {
    window
      .open(
        generatePath(AdminRoutes.ChecklistBasicAttribute, { attributeId: values?.attributeId }),
        '_blank'
      )
      .focus();
  }, [values?.attributeId]);

  const handleOpenNtestedChecklist = useCallback(() => {
    window
      .open(
        generatePath(AdminRoutes.SelectedChecklist, { checklistId: values?.checklistLink }),
        '_blank'
      )
      .focus();
  }, [values?.checklistLink]);

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Button onClick={submitForm} variant="contained">
          Сохранить
        </Button>
        <Button
          onClick={() => {
            closeModal();
            clearCurrentChecklistAttributeEditFileList();
          }}
          variant="text"
        >
          Закрыть
        </Button>
      </Box>
      {errorMessage && (
        <Typography fontSize={12} color={Colors.red}>
          {errorMessage}
        </Typography>
      )}

      <Box maxHeight="80vh" overflow="auto" width="100%" marginBottom={2} paddingTop={2}>
        <FormikProvider value={formik}>
          {/* <Button onClick={() => setFieldValue('type', 'ALL')}>All</Button> */}
          <TabContext value={tab}>
            <TabList onChange={handleChangeTab}>
              <Tab label="Параметры атрибута" value="1" />
              {isDisplayUserDictionaryLinkParams && <Tab label="Значения атрибута" value="2" />}
            </TabList>
            <TabPanel value="1">
              <Box>
                <FormRow>
                  <FormInput
                    itemKey="name"
                    attributeScheme={attributeInChecklistFormItemsScheme}
                    disabled={Boolean(id)}
                    autocompleteConfig={{
                      optionsFetchHandler: getActiveChecklistAttributeList,
                      isOnMountOptionsFetch: true,
                      onSelect: onSelectBaseAttribute,
                      searchingKey: 'name',
                      isClearable: true,
                      optionItem: {
                        tooltip: {
                          fieldName: 'description',
                          placement: 'top',
                        },
                      },
                      pagination: {},
                    }}
                  />
                  <FormInput itemKey="type" attributeScheme={attributeInChecklistFormItemsScheme} />
                  <FormInput
                    itemKey="attributeId"
                    attributeScheme={attributeInChecklistFormItemsScheme}
                  />
                  {values?.attributeId && (
                    <Tooltip title="Открыть базовый атрибут в новом окне">
                      <IconButton onClick={handleOpenBaseAttribute}>
                        <ReplyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('calculationType') && (
                    <FormInput
                      itemKey="calculationType"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('nestedChecklistName') && (
                    <FormInput
                      itemKey="nestedChecklistName"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('checklistLink') && (
                    <FormInput
                      itemKey="checklistLink"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {values?.checklistLink && (
                    <Tooltip title="Открыть вложенный чек-лист в новом окне">
                      <IconButton onClick={handleOpenNtestedChecklist}>
                        <ReplyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('dictionaryLink') && (
                    <FormInput
                      itemKey="dictionaryLink"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('dictionaryLinkAlias') && (
                    <FormInput
                      itemKey="dictionaryLinkAlias"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('displayParent') && (
                    <FormInput
                      itemKey="displayParent"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow hide={isDefault}>
                  {selectedAttributeScheme.has('stage') && (
                    <FormInput
                      itemKey="stage"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      autocompleteConfig={{
                        optionsFetchHandler: fetchChecklistStageList,
                        isOnMountOptionsFetch: true,
                        onChangeMappingKey: 'stageId',
                        value: stageData?.name,
                        searchingParams: {
                          checklistId: currentChecklist.id,
                        },
                      }}
                    />
                  )}
                  {selectedAttributeScheme.has('stageId') && (
                    <FormInput
                      itemKey="stageId"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('order') && (
                    <FormInput
                      itemKey="order"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('id') && (
                    <FormInput itemKey="id" attributeScheme={attributeInChecklistFormItemsScheme} />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isTitle') && (
                    <FormInput
                      itemKey="isTitle"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isRequired') && (
                    <FormInput
                      itemKey="isRequired"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('isMultiselect') && (
                    <FormInput
                      itemKey="isMultiselect"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      disabled={true}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('parentAttribute') && (
                    <FormInput
                      itemKey="parentAttribute"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      autocompleteConfig={{
                        optionsFetchHandler: getChecklistAttributeList,
                        optionsMappingScheme: (value: IGetChecklistAttribute) => {
                          return {
                            label: `${value.attribute.name} / ${value.id}`,
                            value: value.id,
                            rawData: value,
                          };
                        },
                        onChangeMappingKey: 'parentId',
                        searchingParams: {
                          checkListId: values.checkListId,
                          activeOnly: true,
                          attributeType: 'BOOLEAN',
                          ...(values.stageId && { stageId: values.stageId }),
                        },
                      }}
                    />
                  )}
                  {selectedAttributeScheme.has('parentId') && (
                    <FormInput
                      itemKey="parentId"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('min') && (
                    <FormInput
                      itemKey="min"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('normal') && (
                    <FormInput
                      itemKey="normal"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                  {selectedAttributeScheme.has('max') && (
                    <FormInput
                      itemKey="max"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                    />
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('dependencyType') && (
                    <FormInput
                      itemKey="dependencyType"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      onChangeHandler={value => {
                        console.log(value);
                      }}
                    />
                  )}
                  {selectedAttributeScheme.has('dependencyTypeAttribute') &&
                    Boolean(values?.dependencyType) && (
                      <FormInput
                        itemKey="dependencyTypeAttribute"
                        attributeScheme={attributeInChecklistFormItemsScheme}
                        autocompleteConfig={dependencyTypeAttributeConfig}
                        disabled={values?.dependencyType !== 'CHECKLIST_ATTRIBUTE'}
                      />
                    )}
                  {selectedAttributeScheme.has('dependencyAttributeAlias') &&
                    Boolean(values?.dependencyType) && (
                      <FormInput
                        itemKey="dependencyAttributeAlias"
                        attributeScheme={attributeInChecklistFormItemsScheme}
                      />
                    )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('fileRequired') && (
                    <FormInput
                      itemKey="fileRequired"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      onChangeHandler={(value: boolean) => {
                        if (!value) {
                          setFieldValue('imageAttribute', null);
                          setFieldValue('imageAttributeId', null);
                          setFieldValue('imageFileRequired', null);
                        }
                      }}
                    />
                  )}
                </FormRow>
                <FormRow hide={!values?.fileRequired}>
                  <FileSubform />
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('toolTip') && (
                    <Box display="flex" flexDirection="column" width="100%">
                      <FormInput
                        itemKey="toolTip"
                        attributeScheme={attributeInChecklistFormItemsScheme}
                      />
                      <Button
                        onClick={() => useDefaultAttributeValue('toolTip')}
                        variant="text"
                        size="small"
                        sx={{ maxWidth: 260 }}
                      >
                        Подставить из базового атрибута
                      </Button>
                    </Box>
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('placeholder') && (
                    <Box display="flex" flexDirection="column" width="100%">
                      <FormInput
                        itemKey="placeholder"
                        attributeScheme={attributeInChecklistFormItemsScheme}
                      />
                      <Button
                        onClick={() => useDefaultAttributeValue('placeholder')}
                        variant="text"
                        size="small"
                        sx={{ maxWidth: 260 }}
                      >
                        Подставить из базового атрибута
                      </Button>
                    </Box>
                  )}
                </FormRow>
                <FormRow>
                  {selectedAttributeScheme.has('description') && (
                    <FormInput
                      itemKey="description"
                      attributeScheme={attributeInChecklistFormItemsScheme}
                      disabled
                    />
                  )}
                </FormRow>
                <FormRow hide={!Boolean(id)}>
                  <TextField
                    disabled
                    label="Дата создания"
                    value={
                      currentChecklistAttributeEdit?.creationDate
                        ? moment(currentChecklistAttributeEdit?.creationDate).format('DD.MM.YYYY')
                        : ''
                    }
                    fullWidth
                  />
                  <TextField
                    disabled
                    label="Автор"
                    value={currentChecklistAttributeEdit?.createdBy?.fullName}
                    fullWidth
                  />
                  <TextField
                    disabled
                    label="Статус"
                    value={currentChecklistAttributeEdit?.isActive ? 'Активный' : 'Неактивный'}
                    fullWidth
                  />
                </FormRow>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <UserDictionaryLinkTab />
            </TabPanel>
          </TabContext>
        </FormikProvider>
      </Box>
    </>
  );
});

export default CreateAttributeModal;
