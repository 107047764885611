import { Box } from '@mui/system';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from '@farmlink/farmik-ui';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { observer } from 'mobx-react';
import { orderBy } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import { toJS } from 'mobx';

import { IGetChecklistAttribute } from '../../../../../../../../../../api/models/checklist.model';
import { ChecklistsAttributeController } from '../../../../../../../../../controllers/checklistAttribute.controller';
import { useStore } from '../../../../../../../../../shared/utils';
import AdornmentButtons from '../AdornmentButtons/AdornmentButtons';
import { CREATE_ATTRIBUTE_MODAL } from '../../modals';
import { Colors } from '../../../../../../../../../../constants/colors';
import { ChecklistCRUDController } from '../../../../../../../../../controllers/checklist.CRUD.controller';
import { EChecklistAttributeType } from '../../../../../../../../../../api/models/checklist.attribute.model';
import { ChecklistAttributesStore } from '../../../../../../../../../stores/checkLists/attributes/checklistAttributes.store';

import Styled from './styled';

interface IProps {
  data: IGetChecklistAttribute;
  nestedIndex: number;
  parentMap?: Map<string, IGetChecklistAttribute[]>;
}

const Attribute: FC<IProps> = observer(({ data: attributeData, nestedIndex, parentMap }) => {
  const { setCurrentChecklistAttributeEdit, changeAttributeInChecklistActivity } = useStore(
    ChecklistsAttributeController
  );
  const { setCurrentStageData, currentChecklistStageList, currentChecklist } = useStore(
    ChecklistCRUDController
  );
  const { isBasicAttributeExistedInStage } = useStore(ChecklistAttributesStore);
  const [data, setData] = useState(attributeData);
  const [isAllowToActiveAnother, setIsAllowToActiveAnother] = useState(false);

  const { openModalByModalId } = useModal();

  const childAttributeList = parentMap?.get(attributeData.id);

  const isBasicAttributeExisted =
    data.attribute.type !== EChecklistAttributeType.File_link &&
    isBasicAttributeExistedInStage(
      attributeData?.stageId ?? 'noStage',
      attributeData?.attribute.id
    );

  useEffect(() => {
    setData(attributeData);
  }, [attributeData]);

  useEffect(() => {
    setIsAllowToActiveAnother(
      !parentMap?.has(data.parentId) ||
        parentMap
          ?.get(data.parentId)
          ?.filter(
            attr => attr.attribute.type === EChecklistAttributeType.File_link && attr?.isActive
          )?.length === 0
    );
  }, [parentMap]);

  const subtitleList = useMemo<string[]>(() => {
    const subtitle = [`№ ${data.order}`, data.attribute.type];

    if (data?.attribute?.dictionaryLink) subtitle.push(data?.attribute?.dictionaryLink);
    if (data?.displayParent) subtitle.push('Выводить родителя');
    if (data?.isTitle) subtitle.push('Как заголовок');
    if (data?.attribute?.isMultiselect) subtitle.push('Мультивыбор');
    if (data?.fileRequired) subtitle.push('Есть фото');
    if (data?.calculationType) subtitle.push('Агрегация');
    if (data?.dependencyType) subtitle.push(data?.dependencyType);

    return subtitle;
  }, [data]);

  const handleEditAttribute = useCallback(() => {
    setCurrentChecklistAttributeEdit(data, currentChecklist?.nested);
    setCurrentStageData(currentChecklistStageList.find(item => item.id === data?.stageId));
    openModalByModalId(CREATE_ATTRIBUTE_MODAL);
  }, [data, currentChecklistStageList, currentChecklist?.nested]);

  const handleUnlockAttribute = useCallback(() => {
    changeAttributeInChecklistActivity(data.id, true).then(value => {
      setData(value);
    });
  }, [data, childAttributeList]);

  const handleLockAttribute = useCallback(() => {
    changeAttributeInChecklistActivity(data.id, false).then(value => {
      setData(value);
    });
  }, [data]);

  if (childAttributeList?.length) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ backgroundColor: Colors.lightnessGray }}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <Box display="flex" gap="2px">
                <Styled.Title fontWeight={600}>{data.attribute.name}</Styled.Title>
                {data.isRequired && <Styled.RequiredSign>*</Styled.RequiredSign>}
              </Box>

              <Styled.ActionButtonsWrapper>
                <AdornmentButtons
                  hideDelete
                  onEditClick={handleEditAttribute}
                  isLockOpen={data.isActive}
                  onUnlockClick={handleUnlockAttribute}
                  onLockClick={handleLockAttribute}
                />
              </Styled.ActionButtonsWrapper>
            </Box>
            <Box>
              <Styled.Subtitle>
                <SubdirectoryArrowRightIcon sx={{ fontSize: 16 }} /> {subtitleList.join(', ')}
              </Styled.Subtitle>
            </Box>
          </Box>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {orderBy(childAttributeList, ['order', 'id'])?.map(attribute => (
            <Attribute
              data={attribute}
              nestedIndex={nestedIndex + 1}
              key={attribute.id}
              parentMap={parentMap}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Box padding="0 16px" marginBottom={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" position="relative">
        <Box display="flex" gap="2px">
          <Styled.Title fontWeight={600}>{data.attribute.name}</Styled.Title>
          {data.isRequired && <Styled.RequiredSign>*</Styled.RequiredSign>}
        </Box>
        <Styled.ActionButtonsWrapper>
          <AdornmentButtons
            hideDelete
            onEditClick={handleEditAttribute}
            isLockOpen={data.isActive}
            onUnlockClick={handleUnlockAttribute}
            onLockClick={handleLockAttribute}
            {...(isBasicAttributeExisted
              ? {
                  disableLockToggler: { isLockClose: true },
                  tooltipConfig: {
                    lockToggler: { isLockClose: 'Базовый атрибут с таким id уже активен' },
                  },
                }
              : {})}
            {...(isAllowToActiveAnother
              ? {}
              : {
                  disableLockToggler: { isLockClose: true },
                  tooltipConfig: {
                    lockToggler: { isLockClose: 'FILE_LINK уже активен' },
                  },
                })}
          />
        </Styled.ActionButtonsWrapper>
      </Box>
      <Styled.Subtitle>
        <SubdirectoryArrowRightIcon sx={{ fontSize: 16 }} /> {subtitleList.join(', ')}
      </Styled.Subtitle>
    </Box>
  );
});

export default memo(Attribute);
