import { ISelectOption } from '../../types/selectOption';

import { EChecklistAttributeType } from './checklist.attribute.model';
import { IGetChecklistInstance, IPutNestedChecklistInstance } from './checklist.instance.model';
import { TDictionaryItem } from './dictionaries.model';

export interface IChecklist {
  id: string;
  publicId: string;
  name: string;
  checkListSettings: Partial<IChecklistSettingsEntity | IChecklistSettingsEntityShort>[];
  organizations: IChecklistOrganizationItem[];
  machinery?: boolean;
  active?: boolean;
  default?: boolean;
  nested?: boolean;
  field?: boolean;
  type: EChecklistType;
}

export interface IChecklistOrganizationItem {
  name: string;
  organizationId: string;
  INN?: string;
  type?: {
    orgTypeId: number;
    OrgType: string;
  };
}

export interface IChecklistSettingsEntity {
  id: string;
  checkListId: string;
  operationType: {
    id: string;
    name: string;
    noCulture?: boolean;
    intensityRequired?: boolean;
    iconLink?: string;
    type?: string;
  };
  culture: {
    id: string;
    name: string;
  };
  phenophase?: {
    id: string;
    name: string;
    value?: number;
  };
}

export interface IChecklistSettingsEntityShort {
  id?: string;
  operationTypeId: string;
  cultureId: string;
  phenophaseId: string;
  checkListId?: string;
}

export interface IChecklistShort {
  id: string;
  name: string;
  checkListSettings: [
    {
      id: string;
      checkListId: string;
      operationType: {
        id: string;
        name: string;
        noCulture: boolean;
        intensityRequired: boolean;
        iconLink: string;
        type: string;
      };
      culture: {
        id: string;
        name: string;
      };
      phenophase: {
        id: string;
        name: string;
        value: number;
      };
    }
  ];
  culture: {
    id: string;
    name: string;
  };
  techOperation: {
    id: string;
    name: string;
  };
}

export interface IGetChecklistAttribute {
  name: string;
  order: number;
  isRequired: boolean;
  isActive: boolean;
  isTitle: boolean;
  parentId?: string;
  min?: number;
  max?: number;
  normal?: number;
  stageId?: string;
  id: string;
  checkListId: string;
  fileRequired?: boolean;
  attribute: {
    id: string;
    type: EChecklistAttributeType;
    name: string;
    checklistLink: string | null;
    checklistLinkPublicId: string | null;
    dictionaryLink: string | null;
    isMultiselect: boolean;
    toolTip?: string;
    placeholder?: string;
  };
  hasChildren: boolean;
  toolTip?: string;
  dependencyAttribute?: IGetChecklistAttribute;
  calculationType?: string;
  dependencyType?: string;
  dependencyAttributeId?: string;
  displayParent?: boolean;
  checkList: IChecklistShort;
  creationDate?: string;
  createdBy?: {
    id?: string;
    fullName?: string;
  };
}

export interface IChecklistAttributeFileValue {
  id: string;
  fileName?: string;
  fileContentType?: string;
  fileSize?: number;
  downloadUrl?: string;
}

export interface IGetChecklistAttributeValue {
  id: string;
  checkListAttributeId: string;
  integerValue?: number;
  doubleValue?: number;
  stringValue?: string;
  longStringValue?: string;
  booleanValue?: boolean;
  enumValues?: IGetChecklistAttributeEnumValue[];
  userDictionaryValues?: IGetChecklistAttributeUserDictionary[];
  dictionaryValueList?: TDictionaryItem[];
  fileValue?: IChecklistAttributeFileValue[];
  dateValue?: string;
  checkListInstanceValue?: IGetChecklistInstance[];
}

export interface IPutChecklistAttributeValue {
  checkListAttributeId: string;
  integerValue?: number | string;
  doubleValue?: number | string;
  stringValue?: string;
  longStringValue?: string;
  booleanValue?: boolean;
  enumValues?: string[];
  userDictionaryValues?: IGetChecklistAttributeUserDictionary[];
  dictionaryValueList?: string[];
  dictionaryValueListCrumbs?: string[];
  fileValue?: string[];
  dateValue?: string;
  checkListInstanceValue?: IPutNestedChecklistInstance[];
}

export interface IGetChecklistAttributeEnumValue {
  id: string;
  value: string;
}

export interface IGetChecklistAttributeUserDictionary {
  id: string;
  value: string;
  clientId?: null | string;
}

export interface IChecklistAttributeWithValue {
  stageId: string;
  isActive: boolean;
  checklistAttribute: IGetChecklistAttribute;
  attributeValue: IPutChecklistAttributeValue;
  schema: {
    isShownError: boolean;
    error: string;
  };
  listOfOptionValue?: ISelectOption[];
  drawingNestedInstanceId?: string;
  progenitorId?: string;
  attributeWithFileValue?: IChecklistAttributeWithValue;
  children?: IChecklistAttributeWithValue[];
}

export enum EChecklistTabs {
  Organizations = 'organizations',
  Settings = 'settings',
  Attributes = 'attributes',
}

export enum EChecklistType {
  Point = 'POINT',
  Machinery = 'MACHINERY',
  Field = 'FIELD',
  Nested = 'NESTED',
}
