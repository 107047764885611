import { TypeApiRoute } from '../../../models/type.api.request';

import {
  IChecklistAttribute,
  EChecklistAttributeType,
  EChecklistAttributeCalculationType,
} from './../../../models/checklist.attribute.model';

type TypeRequest = {
  id: string;
  name?: string;
  isRequired?: boolean;
  isActive?: boolean;
  checklistLink?: string;
  checklistLinkPublicId?: string;
  dictionaryLink?: string;
  isMultiselect?: boolean;
  displayParent?: boolean;
  toolTip?: string;
  placeholder?: string;
  description?: string;
  calculationType?: EChecklistAttributeCalculationType;
  isTitle?: boolean;
  type?: EChecklistAttributeType;
};

type TypeResponse = IChecklistAttribute;

type TypeError = {};

export const changeChecklistAttribute: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/attribute/${request.id}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
