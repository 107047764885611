import { TypeApiRoute } from '../../../models/type.api.request';
import { EChecklistAttributeType } from '../../../models/checklist.attribute.model';
import { TypeResponsePageable } from '../../../models/response.pageable.model';
import { IGetChecklistAttribute } from '../../../models/checklist.model';

type TypeRequest = {
  name?: string;
  type?: EChecklistAttributeType;
  size?: number;
  page?: number;
};

type TypeResponse = { content: IGetChecklistAttribute[] } & TypeResponsePageable;

type TypeError = {};

export const getActiveChecklistAttributeList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/attribute/list/active`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
