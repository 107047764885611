import { TypeApiRoute } from '../../../models/type.api.request';
import { IChecklistAttribute } from '../../../models/checklist.attribute.model';

type TypeRequest = {
  id: string;
  activeValue: boolean;
};

type TypeResponse = IChecklistAttribute;

type TypeError = {};

export const changeChecklistAttributeActivity: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: request => `/api/as-fields/admin/attribute/${request.id}/active/${request.activeValue}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
