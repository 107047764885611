/* eslint-disable no-console */
import { Axios, lazyInject, provide, TypeApiRequest } from '../../../shared/utils';

@provide.singleton()
export class ChecklistAttributeService {
  @lazyInject(Axios)
  protected axios: Axios;

  fetchChecklistAttributeListByParams = (
    payload: TypeApiRequest<'getChecklistAttributeListByParams'>
  ) => {
    try {
      return this.axios.api
        .getChecklistAttributeListByParams(payload, { omit: ['page', 'size'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Fetch checklist attributes list error`, error);
    }
  };

  createChecklistAttribute = (payload: TypeApiRequest<'createChecklistAttribute'>) => {
    try {
      return this.axios.api
        .createChecklistAttribute(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Create checklist attribute error`, error);
    }
  };

  getChecklistAttribute = (payload: TypeApiRequest<'getChecklistAttribute'>) => {
    try {
      return this.axios.api
        .getChecklistAttribute(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get checklist attribute error`, error);
    }
  };

  getChecklistAttributeList = (payload: TypeApiRequest<'getChecklistAttributeList'>) => {
    try {
      return this.axios.api
        .getChecklistAttributeList(payload, {
          omit: ['size', 'page'],
          query: { size: payload?.size ?? 100, page: payload?.page ?? 0 },
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get checklist attribute list error`, error);
    }
  };

  changeChecklistAttribute = (payload: TypeApiRequest<'changeChecklistAttribute'>) => {
    try {
      return this.axios.api
        .changeChecklistAttribute(payload, { omit: ['id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Change checklist attribute error`, error);
    }
  };

  changeChecklistAttributeActivity = (
    payload: TypeApiRequest<'changeChecklistAttributeActivity'>
  ) => {
    try {
      return this.axios.api
        .changeChecklistAttributeActivity(payload, { omit: ['id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Change checklist attribute activity error`, error);
    }
  };

  deleteChecklistAttribute = (payload: TypeApiRequest<'deleteChecklistAttribute'>) => {
    try {
      return this.axios.api
        .deleteChecklistAttribute(payload, { omit: ['id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Change checklist attribute activity error`, error);
    }
  };

  getChecklistAttributeEnumList = (payload: TypeApiRequest<'getChecklistAttributeEnumList'>) => {
    try {
      return this.axios.api
        .getChecklistAttributeEnumList(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get checklist attribute enum list error`, error);
    }
  };

  changeChecklistAttributeEnumList = (
    payload: TypeApiRequest<'changeChecklistAttributeEnumList'>
  ) => {
    try {
      return this.axios.api
        .changeChecklistAttributeEnumList(payload as any)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get checklist attribute enum list error`, error);
    }
  };

  getActiveChecklistAttributeList = (
    payload: TypeApiRequest<'getActiveChecklistAttributeList'>
  ) => {
    try {
      return this.axios.api
        .getActiveChecklistAttributeList(payload as any, {
          query: { size: payload.size },
          omit: ['size', 'page'],
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get active checklist attribute list error`, error);
    }
  };

  createAttributeInChecklist = (payload: TypeApiRequest<'createAttributeInChecklist'>) => {
    try {
      return this.axios.api
        .createAttributeInChecklist(payload as any)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Create attribute in checklist error`, error);
    }
  };

  changeAttributeInChecklist = (
    payload: TypeApiRequest<'changeAttributeInChecklist'>,
    ignoreNulls?: boolean
  ) => {
    try {
      return this.axios.api
        .changeAttributeInChecklist(payload, {
          omit: ['id'],
          ...(typeof ignoreNulls === 'boolean' ? { ignoreNulls } : {}),
        })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Change attribute in checklist attribute error`, error);
    }
  };

  getChecklistAttributeUserDictionaryList = (
    payload: TypeApiRequest<'getChecklistAttributeUserDictionaryList'>
  ) => {
    try {
      return this.axios.api
        .getChecklistAttributeUserDictionaryList(payload, { omit: ['attributeId'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get userDictionary data in checklist attribute error`, error);
    }
  };

  changeChecklistAttributeUserDictionaryList = (
    payload: TypeApiRequest<'changeChecklistAttributeUserDictionaryList'>
  ) => {
    try {
      return this.axios.api
        .changeChecklistAttributeUserDictionaryList(payload)
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Change attribute in checklist attribute error`, error);
    }
  };

  getAttributeInChecklist = (payload: TypeApiRequest<'getAttributeInChecklist'>) => {
    try {
      return this.axios.api
        .getAttributeInChecklist(payload, { omit: ['id'] })
        .then(data => {
          return data;
        })
        .catch(e => {
          throw e;
        });
    } catch (error) {
      console.error(`Get attribute in checklist attribute error`, error);
    }
  };

  getEnumDependency = (payload: TypeApiRequest<'getEnumDependency'>) => {
    try {
      return this.axios.api.getEnumDependency(payload);
    } catch (error) {
      console.error('Get enum dependencies error', error);
    }
  };

  createEnumDependencyLink = (payload: TypeApiRequest<'createEnumDependencyLink'>) => {
    try {
      return this.axios.api.createEnumDependencyLink(payload);
    } catch (error) {
      console.error('Create enum dependencies error', error);
    }
  };

  getEnumDependencyLinkList = (payload: TypeApiRequest<'getEnumDependencyLinkList'>) => {
    try {
      return this.axios.api.getEnumDependencyLinkList(payload, {
        query: { size: payload?.size ?? 20 },
      });
    } catch (error) {
      console.error('Get enum dependency link list error', error);
    }
  };

  deleteEnumDependencyLink = (payload: TypeApiRequest<'deleteEnumDependency'>) => {
    try {
      return this.axios.api.deleteEnumDependency(payload, { omit: ['id'] });
    } catch (error) {
      console.error('Delete enum dependency link error', error);
    }
  };
}
